import styled from "styled-components"
import CustomImage from "../custom-image/custom-image.component"
import { PlayCircle } from "@mui/icons-material"

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const VideoContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  cursor: pointer;
`

export const AboutImage = styled(CustomImage)`
  width: 100%;
  height: 500px;
  border-radius: 10px;
  overflow: hidden;
  display: block;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: 300px;
  }
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.color};
  border-radius: 10px;
  opacity: ${props => props.opacity};
`

export const PlayCircleIcon = styled(PlayCircle)`
  color: white;
  width: 3em;
  height: 3em;
`
