import React from "react"
import { textEllipsis } from "../../utils"
import * as S from "./card-related-article.styles"

const CardRelatedArticle = ({ image, title, category, url, pageTitle }) => {
  return (
    <S.CardContainer url={url}>
      <S.Card>
        <S.ImageContainer>
          <S.Image img={image} alt={title} />
          <S.ImageOverlay className="overlay">
            {/*{pageTitle === "Articles" && <S.LinkedInIcon />}*/}
            <S.LinkedInIcon />
          </S.ImageOverlay>
        </S.ImageContainer>
        <S.Category>{category}</S.Category>
        <S.Title>{title}</S.Title>
      </S.Card>
    </S.CardContainer>
  )
}

export default CardRelatedArticle
