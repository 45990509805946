import React, { useEffect, useState } from "react"
import * as S from "./other-post-grid-pagination.styles"
import { Grid } from "@mui/material"
import CardRelatedArticle from "../card-related-article/card-related-article.component"
import CustomPagination from "../custom-pagination/custom-pagination.component"

const OtherPostGridPagination = ({
  articles,
  postPerPage,
  titleSection,
  pageTitle,
  idFeatured,
}) => {
  const [articlesToShow, setArticlesToShow] = useState([])

  /*Pagination*/
  const POST_PER_PAGE = postPerPage ?? 6
  const [paginationCount, setPaginationCount] = useState(0)
  const [page, setPage] = useState(1)
  const [initialArticle, setInitialArticle] = useState(1)
  const [finalArticle, setFinalArticle] = useState(POST_PER_PAGE)

  let validateArticles = []
  articles.map(item => item.id !== idFeatured && validateArticles.push(item))

  const handlePaginationChange = (event, value) => {
    setPage(value)
  }

  useEffect(() => {
    setInitialArticle(1)
    setFinalArticle(
      initialArticle + POST_PER_PAGE > articles?.length - 1
        ? articles?.length - 1
        : finalArticle + POST_PER_PAGE
    )
  }, [])

  useEffect(() => {
    setArticlesToShow(
      validateArticles?.slice(
        page * POST_PER_PAGE - POST_PER_PAGE,
        page * POST_PER_PAGE
      )
    )

    setPaginationCount(Math.ceil((articles?.length - 1) / POST_PER_PAGE))

    if (articles?.length - 1 <= POST_PER_PAGE * (page - 1)) {
      setPage(1)
    }
  }, [page, articles])

  const paginationLocal = {
    currentPage: page,
    numPages: paginationCount,
  }
  // console.log(articlesToShow)
  if (!articles || articlesToShow?.length < 1) return null
  return (
    <S.Wrapper>
      {titleSection && <S.SectionTitle>{titleSection}</S.SectionTitle>}
      <Grid container spacing={6} id="post-top">
        {articlesToShow?.map((article, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={postPerPage === 2 ? 6 : 4}
            key={`other-articles-${index}`}
          >
            <CardRelatedArticle
              title={article?.title}
              image={article?.featuredImage?.node}
              category={
                article?.categories
                  ? article?.categories?.nodes[0]?.name
                  : article?.caseStudyCategories?.nodes[0]?.name ??
                    article?.whitepaperCategories?.nodes[0]?.name
              }
              url={
                pageTitle === "Articles"
                  ? `/articles/${article?.slug}`
                  : `/resources${article?.uri}`
              }
              pageTitle={pageTitle}
            />
          </Grid>
        ))}
      </Grid>
      <S.PaginationContainer>
        <CustomPagination
          isOnePage={POST_PER_PAGE >= articles?.length - 1}
          scrollTo="post-top"
          pageContext={paginationLocal}
          handlePagination={handlePaginationChange}
        />
      </S.PaginationContainer>
    </S.Wrapper>
  )
}

export default OtherPostGridPagination
