import styled from "styled-components"
import CustomImage from "../custom-image/custom-image.component"
import CustomLink from "../custom-link/custom-link.component"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"

export const CardContainer = styled(CustomLink)`
  &:hover {
    h3,
    p {
      color: ${({ theme }) => theme.palette.secondary.main};
    }
    .overlay {
      opacity: 1;
    }
  }
`
export const Card = styled.div``
export const ImageContainer = styled.div`
  display: block;
  position: relative;
`
export const Image = styled(CustomImage)`
  overflow: hidden;
  height: 310px;
  border-radius: 10px;
`
export const ImageOverlay = styled.div`
  background-color: rgba(0, 119, 179, 0.7);
  border-radius: 10px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.5s ease all;
`

export const LinkedInIcon = styled(AddCircleOutlineOutlinedIcon)`
  color: #ffffff;
  opacity: 1 !important;
  width: 64px;
  height: 64px;
`
export const Category = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  color: ${({ theme }) => theme.palette.secondary.main};
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
`
export const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(36)};
  font-weight: 500;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`
