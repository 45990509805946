import styled from "styled-components"

export const Wrapper = styled.div`
  padding: 3em 0 1em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 0.5em 0 1em;
  }
`

export const SectionTitle = styled.h2`
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  font-family: ${({ theme }) => theme.fonts.secondary};
  margin-bottom: 1.3em;
  text-transform: capitalize;
  font-weight: 500;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2em 0;
`
