import React, { useState } from "react"
import * as S from "./video-post.styles"
import LoadableModalVideo from "../../components/loadable-modal-video/loadable-modal-video.component"
import "react-modal-video/css/modal-video.min.css"

const VideoPost = ({ youtubeId, url, image, overlayColor = true }) => {
  const [isVideoOpen, setVideoOpen] = useState(false)
  return (
    <S.Wrapper>
      <>
        <S.VideoContainer
          onClick={() => (youtubeId || url ? setVideoOpen(true) : "")}
        >
          <S.AboutImage img={image} alt="Post" />
          <S.Overlay
            color={overlayColor ? "#0077B3" : "transparent"}
            opacity={overlayColor ? "0.3" : "1"}
          >
            <S.PlayCircleIcon />
          </S.Overlay>
        </S.VideoContainer>
        {youtubeId && (
          <LoadableModalVideo
            channel={"youtube"}
            isOpen={isVideoOpen}
            videoId={youtubeId}
            autoplay={1}
            onClose={() => setVideoOpen(false)}
          />
        )}
        {url && (
          <LoadableModalVideo
            channel={"custom"}
            isOpen={isVideoOpen}
            url={url}
            autoplay={1}
            onClose={() => setVideoOpen(false)}
          />
        )}
      </>
    </S.Wrapper>
  )
}

export default VideoPost
