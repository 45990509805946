import PaginationItem from "@mui/material/PaginationItem"
import styled from "styled-components"

export const CustomPaginationItem = styled(PaginationItem)`
  &.cNMtRq {
    border-radius: unset;

    &:hover {
      background-color: unset;
    }
  }

  &.Mui-selected {
    border-radius: unset;
    background-color: unset;
    border-bottom: 4px solid ${({ theme }) => theme.palette.secondary.main};
    max-width: 16px;
  }
`
